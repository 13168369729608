var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "pa-0": "" } },
    [
      _c("header", { staticClass: "app-welcome-message" }, [
        !_setup.isAddingMasterMode && !_setup.isAddingDetailMode
          ? _c("h2", [_vm._v("Details van locatie")])
          : _vm._e(),
        _setup.isAddingMasterMode
          ? _c("h2", [_vm._v("Toevoegen van locatie")])
          : _vm._e(),
        _setup.isAddingDetailMode
          ? _c("h2", [_vm._v("Toevoegen van locatieversie")])
          : _vm._e(),
      ]),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _setup.hasDetails
                        ? _c("v-col", { attrs: { cols: "auto" } }, [
                            _vm._v(" Tijdlijn: "),
                          ])
                        : _vm._e(),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn-toggle",
                            {
                              attrs: {
                                group: "",
                                mandatory: "",
                                color: "blue accent-3",
                              },
                              model: {
                                value: _setup.selectedTabIndex,
                                callback: function ($$v) {
                                  _setup.selectedTabIndex = $$v
                                },
                                expression: "selectedTabIndex",
                              },
                            },
                            _vm._l(_setup.detailTabs(), function (item, index) {
                              return _c(
                                "v-btn",
                                {
                                  key: index,
                                  staticClass: "v-application elevation-2",
                                  attrs: {
                                    value: index,
                                    disabled: !_setup.isReadOnly,
                                  },
                                },
                                [_vm._v(" " + _vm._s(item) + " ")]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                _setup.ValidationObserver,
                { ref: "obs" },
                [
                  _c(
                    "v-form",
                    [
                      _c(
                        "masonry",
                        {
                          attrs: { cols: { default: 2, 1264: 1 }, gutter: 12 },
                        },
                        [
                          _c(
                            "v-container",
                            { staticClass: "boxed", attrs: { fluid: "" } },
                            [
                              _c("header", [_vm._v("Geldigheid")]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        ref: "validFrom",
                                        attrs: {
                                          name: "Geldig vanaf",
                                          rules: "date_between|required",
                                          mode: _setup.checkDateValid,
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(_setup.DateInput, {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEditTL,
                                                  },
                                                  attrs: {
                                                    "persistent-placeholder":
                                                      "",
                                                    label: "Geldig vanaf",
                                                    errors: errors,
                                                    outlined:
                                                      _setup.allowEditTL,
                                                    readonly:
                                                      !_setup.allowEditTL,
                                                    rules: "required",
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData
                                                        .valFrom,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "valFrom",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.valFrom",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Geldig tot",
                                          rules: `date_between|date_after:${_setup.selectedData.valFrom}`,
                                          mode: _setup.checkDateValid,
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(_setup.DateInput, {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEditTL,
                                                  },
                                                  attrs: {
                                                    "persistent-placeholder":
                                                      "",
                                                    label: "Geldig tot",
                                                    errors: errors,
                                                    outlined:
                                                      _setup.allowEditTL,
                                                    readonly:
                                                      !_setup.allowEditTL,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData
                                                        .valTill,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "valTill",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.valTill",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-container",
                            { staticClass: "boxed" },
                            [
                              _c("header", [_vm._v("Aanpassing")]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Aanpassings omschrijving",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-text-field", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit &&
                                                      !_setup.allowEditTL,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    "persistent-placeholder":
                                                      "",
                                                    label: "Omschrijving",
                                                    "error-messages": errors,
                                                    outlined:
                                                      _setup.allowEdit ||
                                                      _setup.allowEditTL,
                                                    readonly:
                                                      !_setup.allowEdit &&
                                                      !_setup.allowEditTL,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData
                                                        .modDescr,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "modDescr",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.modDescr",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              !_setup.isAddingMode
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-text-field", {
                                            staticClass: "noBorders",
                                            attrs: {
                                              "hide-details": "auto",
                                              "persistent-placeholder": "",
                                              label: "Door",
                                              readonly: "",
                                            },
                                            model: {
                                              value: _setup.selectedData.modBy,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _setup.selectedData,
                                                  "modBy",
                                                  $$v
                                                )
                                              },
                                              expression: "selectedData.modBy",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_setup.isAddingMode
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-text-field", {
                                            staticClass: "noBorders",
                                            attrs: {
                                              "hide-details": "auto",
                                              "persistent-placeholder": "",
                                              label: "Datum",
                                              readonly: "",
                                            },
                                            model: {
                                              value: _setup.modDateFormatted,
                                              callback: function ($$v) {
                                                _setup.modDateFormatted = $$v
                                              },
                                              expression: "modDateFormatted",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "masonry",
                        {
                          attrs: {
                            cols: { default: 3, 1264: 2, 768: 1 },
                            gutter: 12,
                          },
                        },
                        [
                          _c(
                            "v-container",
                            { staticClass: "boxed" },
                            [
                              _c("header", [_vm._v("Algemene gegevens")]),
                              _setup.selectedData.reference != 0
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-text-field", {
                                            staticClass: "noBorders",
                                            attrs: {
                                              "hide-details": "auto",
                                              "persistent-placeholder": "",
                                              label: "INNAX-ID",
                                              readonly: "",
                                            },
                                            model: {
                                              value:
                                                _setup.selectedData.reference,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _setup.selectedData,
                                                  "reference",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "selectedData.reference",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Omschrijving",
                                          rules: "required|max:1000",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors, valid }) {
                                              return [
                                                _c("v-text-field", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    "persistent-placeholder":
                                                      "",
                                                    label: "Omschrijving",
                                                    counter: 1000,
                                                    "error-messages": errors,
                                                    success: valid,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData.descr,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "descr",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.descr",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Bouwdatum",
                                          rules:
                                            "date_between:1500-01-01,2050-12-31",
                                          mode: _setup.checkDateValid,
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(_setup.DateInput, {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    value:
                                                      _setup.selectedData
                                                        .consDat,
                                                    "persistent-placeholder":
                                                      "",
                                                    label: "Bouwdatum",
                                                    min: new Date(1500, 0, 1),
                                                    errors: errors,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      _setup.selectedData.consDat =
                                                        $event !== ""
                                                          ? $event
                                                          : undefined
                                                    },
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Renovatiedatum",
                                          rules: "date_between",
                                          mode: _setup.checkDateValid,
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(_setup.DateInput, {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    value:
                                                      _setup.selectedData
                                                        .renovDat,
                                                    "persistent-placeholder":
                                                      "",
                                                    label: "Renovatiedatum",
                                                    errors: errors,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      _setup.selectedData.renovDat =
                                                        $event !== ""
                                                          ? $event
                                                          : undefined
                                                    },
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          label: "Is dit gebouw een monument?",
                                          readonly: _setup.isReadOnly,
                                          "hide-details": "",
                                        },
                                        model: {
                                          value:
                                            _setup.selectedData.isMonumental,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _setup.selectedData,
                                              "isMonumental",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "selectedData.isMonumental",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Gebruikstatus",
                                          rules: "max:50",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors, valid }) {
                                              return [
                                                _c("v-text-field", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    "persistent-placeholder":
                                                      "",
                                                    label: "Gebruikstatus",
                                                    counter: 50,
                                                    "error-messages": errors,
                                                    success: valid,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData
                                                        .usgStat,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "usgStat",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.usgStat",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-container",
                            { staticClass: "boxed" },
                            [
                              _c("header", [_vm._v("Functies")]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Type",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-select", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    "persistent-placeholder":
                                                      "",
                                                    label: "Type",
                                                    items: _setup.locationTypes,
                                                    "item-text": "description",
                                                    "item-value": "key",
                                                    "error-messages": errors,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData.typ,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "typ",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.typ",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-select", {
                                        class: { noBorders: !_setup.allowEdit },
                                        attrs: {
                                          "hide-details": "auto",
                                          "persistent-placeholder": "",
                                          label: "Functie",
                                          items: _setup.ecmFunctions,
                                          "item-text": "name",
                                          "item-value": "id",
                                          outlined: _setup.allowEdit,
                                          readonly: !_setup.allowEdit,
                                        },
                                        model: {
                                          value: _setup.selectedData.ecmFuncId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _setup.selectedData,
                                              "ecmFuncId",
                                              $$v
                                            )
                                          },
                                          expression: "selectedData.ecmFuncId",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "WEii Bouwbesluit",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-select", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    "persistent-placeholder":
                                                      "",
                                                    label: "WEii Bouwbesluit",
                                                    items:
                                                      _setup.weiiBldDecrees,
                                                    "item-text": "name",
                                                    "item-value": "key",
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                    "error-messages": errors,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData
                                                        .weiiBldDecreeKey,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "weiiBldDecreeKey",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.weiiBldDecreeKey",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "WEii Klasse",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-select", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    "persistent-placeholder":
                                                      "",
                                                    label: "WEii Klasse",
                                                    items:
                                                      _setup.weiiBldCategories,
                                                    "item-text": "name",
                                                    "item-value": "key",
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                    "error-messages": errors,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData
                                                        .weiiBldCatKey,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "weiiBldCatKey",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.weiiBldCatKey",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-select", {
                                        class: { noBorders: !_setup.allowEdit },
                                        attrs: {
                                          "hide-details": "auto",
                                          "persistent-placeholder": "",
                                          label: "NTA8800 functie",
                                          items: _setup.nta8800Functions,
                                          "item-text": "descr",
                                          "item-value": "id",
                                          outlined: _setup.allowEdit,
                                          readonly: !_setup.allowEdit,
                                        },
                                        model: {
                                          value:
                                            _setup.selectedData.nta8800FuncId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _setup.selectedData,
                                              "nta8800FuncId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "selectedData.nta8800FuncId",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-select", {
                                        class: { noBorders: !_setup.allowEdit },
                                        attrs: {
                                          "hide-details": "auto",
                                          "persistent-placeholder": "",
                                          label: "EED",
                                          items: _setup.eedTypes,
                                          "item-text": "description",
                                          "item-value": "key",
                                          outlined: _setup.allowEdit,
                                          readonly: !_setup.allowEdit,
                                        },
                                        model: {
                                          value: _setup.selectedData.eedTyp,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _setup.selectedData,
                                              "eedTyp",
                                              $$v
                                            )
                                          },
                                          expression: "selectedData.eedTyp",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: { name: "GBS", rules: "max:50" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors, valid }) {
                                              return [
                                                _c("v-text-field", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    "persistent-placeholder":
                                                      "",
                                                    label: "GBS",
                                                    counter: 50,
                                                    "error-messages": errors,
                                                    success: valid,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData
                                                        .gbsTyp,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "gbsTyp",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.gbsTyp",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-container",
                            { staticClass: "boxed" },
                            [
                              _c("header", [_vm._v("Hernieuwbare energie")]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          label:
                                            "Gebruik van hernieuwbare energie?",
                                          readonly: _setup.isReadOnly,
                                          "hide-details": "",
                                        },
                                        model: {
                                          value:
                                            _setup.selectedData
                                              .isRenewEnergyUsed,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _setup.selectedData,
                                              "isRenewEnergyUsed",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "selectedData.isRenewEnergyUsed",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Aandeel elektriciteit",
                                          rules:
                                            "double|decimal:2|between:0,100.00",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  {
                                                    class: {
                                                      noBorders:
                                                        !_setup.allowEdit,
                                                    },
                                                    attrs: {
                                                      type: "number",
                                                      "hide-details": "auto",
                                                      "persistent-placeholder":
                                                        "",
                                                      label:
                                                        "Aandeel elektriciteit",
                                                      value:
                                                        _setup.selectedData
                                                          .shareOfRenewElecPerc,
                                                      "error-messages": errors,
                                                      outlined:
                                                        _setup.allowEdit,
                                                      readonly:
                                                        !_setup.allowEdit,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        _setup.selectedData.shareOfRenewElecPerc =
                                                          $event !== ""
                                                            ? $event
                                                            : undefined
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("%")]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Aandeel energie",
                                          rules:
                                            "double|decimal:2|between:0,100.00",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  {
                                                    class: {
                                                      noBorders:
                                                        !_setup.allowEdit,
                                                    },
                                                    attrs: {
                                                      type: "number",
                                                      "hide-details": "auto",
                                                      "persistent-placeholder":
                                                        "",
                                                      label: "Aandeel energie",
                                                      value:
                                                        _setup.selectedData
                                                          .shareOfRenewEnergyPerc,
                                                      "error-messages": errors,
                                                      outlined:
                                                        _setup.allowEdit,
                                                      readonly:
                                                        !_setup.allowEdit,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        _setup.selectedData.shareOfRenewEnergyPerc =
                                                          $event !== ""
                                                            ? $event
                                                            : undefined
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("%")]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Aandeel warmte",
                                          rules:
                                            "double|decimal:2|between:0,100.00",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  {
                                                    class: {
                                                      noBorders:
                                                        !_setup.allowEdit,
                                                    },
                                                    attrs: {
                                                      type: "number",
                                                      "hide-details": "auto",
                                                      "persistent-placeholder":
                                                        "",
                                                      label: "Aandeel warmte",
                                                      value:
                                                        _setup.selectedData
                                                          .shareOfRenewHeatPerc,
                                                      "error-messages": errors,
                                                      outlined:
                                                        _setup.allowEdit,
                                                      readonly:
                                                        !_setup.allowEdit,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        _setup.selectedData.shareOfRenewHeatPerc =
                                                          $event !== ""
                                                            ? $event
                                                            : undefined
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("%")]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-container",
                            { staticClass: "boxed" },
                            [
                              _c("header", [_vm._v("Oppervlakten")]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Gebruiksoppervlakte",
                                          rules: "numeric|between:0,9999999",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  {
                                                    class: {
                                                      noBorders:
                                                        !_setup.allowEdit,
                                                    },
                                                    attrs: {
                                                      type: "number",
                                                      "hide-details": "auto",
                                                      "persistent-placeholder":
                                                        "",
                                                      label:
                                                        "Gebruiksoppervlakte (GO)",
                                                      value:
                                                        _setup.selectedData.go,
                                                      "error-messages": errors,
                                                      outlined:
                                                        _setup.allowEdit,
                                                      readonly:
                                                        !_setup.allowEdit,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        _setup.selectedData.go =
                                                          $event !== ""
                                                            ? $event
                                                            : undefined
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("m²")]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Bruto vloeroppervlakte",
                                          rules: "numeric|between:0,9999999",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  {
                                                    class: {
                                                      noBorders:
                                                        !_setup.allowEdit,
                                                    },
                                                    attrs: {
                                                      type: "number",
                                                      "hide-details": "auto",
                                                      "persistent-placeholder":
                                                        "",
                                                      label:
                                                        "Bruto vloeroppervlakte (BVO)",
                                                      value:
                                                        _setup.selectedData.bvo,
                                                      "error-messages": errors,
                                                      outlined:
                                                        _setup.allowEdit,
                                                      readonly:
                                                        !_setup.allowEdit,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        _setup.selectedData.bvo =
                                                          $event !== ""
                                                            ? $event
                                                            : undefined
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("m²")]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Netto vloeroppervlakte",
                                          rules: "numeric|between:0,9999999",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  {
                                                    class: {
                                                      noBorders:
                                                        !_setup.allowEdit,
                                                    },
                                                    attrs: {
                                                      type: "number",
                                                      "hide-details": "auto",
                                                      "persistent-placeholder":
                                                        "",
                                                      label:
                                                        "Netto vloeroppervlakte (NVO)",
                                                      value:
                                                        _setup.selectedData.nvo,
                                                      "error-messages": errors,
                                                      outlined:
                                                        _setup.allowEdit,
                                                      readonly:
                                                        !_setup.allowEdit,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        _setup.selectedData.nvo =
                                                          $event !== ""
                                                            ? $event
                                                            : undefined
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("m²")]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Verhuurbaar vloeroppervlakte",
                                          rules: "numeric|between:0,9999999",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  {
                                                    class: {
                                                      noBorders:
                                                        !_setup.allowEdit,
                                                    },
                                                    attrs: {
                                                      type: "number",
                                                      "hide-details": "auto",
                                                      "persistent-placeholder":
                                                        "",
                                                      label:
                                                        "Verhuurbaar vloeroppervlakte (VVO)",
                                                      value:
                                                        _setup.selectedData.vvo,
                                                      "error-messages": errors,
                                                      outlined:
                                                        _setup.allowEdit,
                                                      readonly:
                                                        !_setup.allowEdit,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        _setup.selectedData.vvo =
                                                          $event !== ""
                                                            ? $event
                                                            : undefined
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("m²")]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Dakoppervlakte",
                                          rules: "numeric|between:0,9999999",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  {
                                                    class: {
                                                      noBorders:
                                                        !_setup.allowEdit,
                                                    },
                                                    attrs: {
                                                      type: "number",
                                                      "hide-details": "auto",
                                                      "persistent-placeholder":
                                                        "",
                                                      label:
                                                        "Dakoppervlakte (DO)",
                                                      value:
                                                        _setup.selectedData.do,
                                                      "error-messages": errors,
                                                      outlined:
                                                        _setup.allowEdit,
                                                      readonly:
                                                        !_setup.allowEdit,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        _setup.selectedData.do =
                                                          $event !== ""
                                                            ? $event
                                                            : undefined
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("m²")]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-container",
                            { staticClass: "boxed" },
                            [
                              _c("header", [_vm._v("Notities")]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(_setup.ValidationProvider, {
                                        attrs: {
                                          name: "Notities",
                                          rules: "max:1000",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c("v-text-field", {
                                                  class: {
                                                    noBorders:
                                                      !_setup.allowEdit,
                                                  },
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    "persistent-placeholder":
                                                      "",
                                                    counter: 1000,
                                                    "error-messages": errors,
                                                    outlined: _setup.allowEdit,
                                                    readonly: !_setup.allowEdit,
                                                  },
                                                  model: {
                                                    value:
                                                      _setup.selectedData.note,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _setup.selectedData,
                                                        "note",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedData.note",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("details-buttons", {
                        attrs: {
                          addMode: _setup.isAddingMode,
                          editMode: !_setup.isReadOnly,
                          allowSave: !_setup.isSaving,
                        },
                        on: {
                          edit: _setup.setEditMode,
                          "edit-tl": _setup.setEditTLMode,
                          "on-cancel": _setup.onCancel,
                          "add-new-detail": _setup.addNewDetail,
                          "on-cancel-add": _setup.onCancelAdd,
                          "save-detail": _setup.saveDetail,
                          "save-new-detail": _setup.saveNewDetail,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }